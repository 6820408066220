// extracted by mini-css-extract-plugin
export var fortune = "FortuneMarketing-module--fortune--32L92";
export var fortuneAbout = "FortuneMarketing-module--fortuneAbout--3fbZs";
export var fortuneAboutContent = "FortuneMarketing-module--fortuneAboutContent--1pfi-";
export var fortuneProblem = "FortuneMarketing-module--fortuneProblem--1JNnF";
export var fortuneProblemContent = "FortuneMarketing-module--fortuneProblemContent--3VnNO";
export var fortuneProblemProfile = "FortuneMarketing-module--fortuneProblemProfile--2W0iT";
export var fortuneProblemProfileDescription = "FortuneMarketing-module--fortuneProblemProfileDescription--1TgE5";
export var fortuneProblemProfileName = "FortuneMarketing-module--fortuneProblemProfileName--27sxG";
export var fortuneProblemProfilePosition = "FortuneMarketing-module--fortuneProblemProfilePosition--1Z72h";
export var fortuneSolution = "FortuneMarketing-module--fortuneSolution--ios4D";
export var mobileTitle = "FortuneMarketing-module--mobileTitle--1aU9o";
export var mobileTitleTwo = "FortuneMarketing-module--mobileTitleTwo--UJ_cg";