import React from "react"
import Layout from "@src/components/Layout"
import FortuneLogo from "@src/icons/case-studies/fortune-marketing-logo.png"
import FortuneAbout from "@src/icons/case-studies/fortune-marketing-about.png"
import AchinProfile from "@src/icons/case-studies/achin-fortune.png"
import Placeholder from "@src/icons/case-studies/placeholder.png"
import FortuneSolutionImage from "@src/icons/case-studies/fortune-marketing-solution.png"
import SplashWithMenu from "../components/SpashWithMenu/SplashWithMenu"
import Row from "../../../V2/components/Row"
import DotBG from "../../../V2/components/DotBG"
import ImageRow from "../../../V2/Features/components/ImageRow/ImageRow"
import Callout from "../../../../components/Callout/Callout"
import OG from "@src/icons/og/fortune-og.jpg"
import Column from "../../../V2/components/Column"

import * as styles from "./FortuneMarketing.module.scss"

const IndexPage = ({ location }) => {
  return (
    <Layout
      title="Fortune Marketing Case Study"
      description="Fortune Marketing transforms distributor operations to drive success"
      url="https://canonic.dev/case-studies/fortune-marketing"
      location={location}
      ogImage={OG}
    >
      <SplashWithMenu
        subtitle={"Case Study"}
        logo={FortuneLogo}
        title={
          <>
            Fortune Marketing <br /> transforms distributor operations to drive
            success
          </>
        }
        // stats={STATS}
        homepage={"https://www.fortune-it.com/"}
      />
      <Row className={styles.fortune} id="about">
        <DotBG />
        <section className={styles.fortuneAbout}>
          {/* <h2>About the brand</h2> */}
          <ImageRow
            screenshot={FortuneAbout}
            title={"About Fortune Marketing Pvt. Ltd."}
            subtitle={
              <p>
                Based in the bustling city of New Delhi, Fortune Marketing
                boasts an impressive roster of top brands, making it a key
                player in the industry. With Achin, their esteemed product and
                business head, leading the charge, Fortune Marketing aims to
                enhance its online channel integration with distributors to
                unlock new avenues of growth
              </p>
            }
            className={styles.fortuneAboutContent}
            reverse
          />
        </section>
        <span id="problem" />
      </Row>
      <Row>
        <section className={styles.fortuneProblem}>
          <div className={styles.fortuneProblemProfile}>
            <figure className={styles.fortuneProblemProfilePicture}>
              <img src={AchinProfile} />
            </figure>
            <h4 className={styles.fortuneProblemProfileName}>Achin Gupta</h4>
            <h5 className={styles.fortuneProblemProfilePosition}>
              Business & Product Head, Fortune Marketing
            </h5>
            {/* <p className={styles.fortuneProblemProfileDescription}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
              lacus nisl, fringilla sed porta fermentum condimentum non nisi.
              Duis in dignissim ante. Sed id ante sed libero molestie efficitur.
              Etiam mollis felis nec dolor
            </p> */}
          </div>
          <div className={styles.fortuneProblemContent}>
            <h3>Understanding Challenges faced</h3>
            <p>
              Fortune Marketing faced several hurdles in its quest for
              operational excellence:
              <ul>
                <li>
                  <p>
                    <strong>
                      Inefficient way to distribute product catalogues:
                    </strong>
                    <br />
                    The reliance on outdated channels like email and WhatsApp
                    for distributing product catalogues and price lists led to
                    delays.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Lack of communication to incentivize distributors:
                    </strong>
                    <br />
                    Fortune Marketing needed ways to motivate its distributors
                    and encourage them to sell more by running reward-based
                    schemes.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>No opportunity to monitor progress:</strong> <br />
                    It was hard for distributors and for Fortune to monitor
                    progress within a scheme, resolve queries and have a
                    seamless communication.
                  </p>
                </li>
              </ul>
            </p>
          </div>
        </section>
      </Row>
      <Row id="solution">
        <DotBG />
        <section>
          {/* <h2 className={styles.mobileTitle}>Fueling success with Canonic</h2> */}
          <ImageRow
            screenshot={FortuneSolutionImage}
            title={"Fueling success with Canonic"}
            subtitle={
              <p>
                Solution built on Canonic’s platform has transformed Fortune
                Marketing's distributor communication landscape in minimal time.
                Discover how our powerful tool directly tackles their pain
                points and drives unprecedented results:
              </p>
            }
            className={styles.fortuneSolution}
            reverse
          />
          <Column.Wrap numPerRow={2}>
            <Column
              title={"Easy data collection from external sources"}
              description={
                "By integrating with Salesforce CRM to pull distributor data, fortune was able to quickly build, access and manage its distributor list."
              }
            />
            <Column
              title={"Streamlined data management within Canonic"}
              description={
                "Our revolutionary CMS empowers Fortune Marketing's internal team with a centralised platform for managing product catalogues and reward schemes. Allowing them to push updates in real-time."
              }
            />
            <Column
              title={"Engage and empower distributors with a custom dashboard"}
              description={
                "With a user-friendly tool designed exclusively for Fortune Marketing's distributors. With just a few clicks, distributors gain access to Fortune's extensive product portfolio, live incentive schemes, and real-time scheme progress updates."
              }
            />
            <Column
              title={"Unified communication channel"}
              description={
                "Say hello to enhanced communication, engagement, and ultimately, increased sales. Resolve queries from the inbox within the tool"
              }
            />
          </Column.Wrap>
        </section>
      </Row>
      <Callout location={location} />
    </Layout>
  )
}

export default IndexPage

// const STATS = [
//   {
//     stat: "100+",
//     subtitle: "HOURS SAVED",
//   },
//   {
//     stat: "65%",
//     subtitle: "MAN POWER SAVED",
//   },
//   {
//     stat: "65%",
//     subtitle: "TIME TO BUILD",
//   },
//   {
//     stat: "9 lac",
//     subtitle: "MONEY SAVED",
//   },
// ]
